import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { PropertyInformation, ArrangeBookingState, formatDate } from "./types";

const DetailsFormStep: React.FunctionComponent<{ property: PropertyInformation, token: string, options: ArrangeBookingState } & Partial<StepWizardChildProps>> = (props) =>
{
    function submitBooking()
    {
        const form = document.querySelector("form#booking-form") as HTMLFormElement;
        if (form.reportValidity())
        {
            form.submit();
        }
    }

    return (
        <section className="form-contact">
            <form id="booking-form" method="post">
                <input type="hidden" name="__RequestVerificationToken" value={props.token} />
                <input type="hidden" name="Type" value={props.options.type ?? ""} />
                <input type="hidden" name="Bedrooms" value={props.options.bedrooms?.toString()} />
                <input type="hidden" name="Date" value={formatDate(props.options.date)} />
                <input type="hidden" name="Time" value={props.options.time?.start} />
                {
                    props.options.additionalProperties.map(
                        e => <input type="hidden" name="AdditionalProperties" value={e.Url} />
                    )
                }
                <fieldset>
                    <legend>Your details:</legend>
                    <div className="fields cols-2">
                        <div className="form-group">
                            <label>First name *</label>
                            <div className="input-control">
                                <input type="text" name="FirstName" required={true} autoComplete="given-name" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Last name *</label>
                            <div className="input-control">
                                <input type="text" name="LastName" required={true} autoComplete="family-name" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Telephone *</label>
                            <div className="input-control">
                                <input type="tel" name="Telephone" required={true} autoComplete="tel" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Email *</label>
                            <div className="input-control">
                                <input type="email" name="Email" required={true} autoComplete="email" />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="booking-summary">
                    <legend>Summary:</legend>
                    <div className="row">
                        <div className={(props.options.bedrooms != null ? "col-md-3" : "col-md-6")}>
                            <h5>Address:</h5>
                            {props.options.type === 'help-me-choose'
                                ?
                                <p>
                                    Cloud Lettings<br />
                                    <small>41 Carholme Road, Lincoln LN1 1RN</small>
                                </p>
                                :
                                <p>
                                    {props.property.Name}<br />
                                    <small>{props.property.Address.LineOne}, {props.property.Address.Town} {props.property.Address.PostalCode}</small>
                                </p>
                            }
                        </div>
                        {
                            props.options.bedrooms != null
                                ? (
                                    <div className="col-md-3">
                                        <h5>Properties:</h5>
                                        {
                                            props.options.bedrooms === -1
                                                ? <p>Any Properties</p>
                                                : (props.options.bedrooms === 7
                                                    ? <p>{props.options.bedrooms}+ Bedroom Properties</p>
                                                    : <p>{props.options.bedrooms} Bedroom Properties</p>)
                                        }

                                    </div>
                                )
                                : null
                        }
                        <div className="col-md-3">
                            <h5>Date:</h5>
                            <p>{props.options.date?.toLocaleDateString('en-GB', { dateStyle: 'full' })}</p>
                        </div>
                        <div className="col-md-3">
                            <h5>Time:</h5>
                            <p>{props.options.time?.start} - {props.options.time?.end}</p>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="text-center">
                    <button type="submit" className="btn btn-primary" onClick={() => submitBooking()}>
                        Submit <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                </fieldset>
            </form>
        </section>
    );
}

export default DetailsFormStep;